.productAdvertBadge {
  span {
    color: @primary-color;
  }

  p {
    display: inline-block;
    margin: 0;
    background-color: fade(@primary-color, 85%);
  }

  &.overlayInfoBadge {
    z-index: @zindex-affix;
    overflow: hidden;

    span {
      color: @white;
    }
  }

  &.detailInfoBadge {
    margin-top: var(--spacing-s);
    grid-area: infoBadge;

    p {
      padding: @spacing-xs;
    }
    @media screen and (min-width: @screen-lg-min) {
      position: absolute;
      top: 260px;
      left: 0;
      width: auto;
      max-width: 430px;
    }
    @media screen and (min-width: @screen-xxl-min) {
      top: 340px;
      max-width: 550px;
    }
  }

  &.listInfoBadge {
    grid-area: advertBadge;

    p {
      width: auto;
      padding: @spacing-xs;
      margin-top: var(--spacing-s);
    }
  }

  &.weekplannerInfoBadge {
    grid-area: infoBadge;

    &.weekplanner-advert-badge--bottom {
      display: flex;
      align-items: flex-end;
    }

    p {
      padding: @spacing-xs;
      margin-bottom: @spacing-xs;
    }
  }
}

.product-advert-badge__weekplanner--default {
  background-color: var(--primary-color);
  color: var(--white);
  text-transform: uppercase;
  writing-mode: vertical-lr;

  &.weekplanner__simple-view {
    // stylelint-disable-next-line
    width: min-content;
    margin-right: 0 !important;
    writing-mode: horizontal-tb;
  }

  @media screen and (min-width: @screen-xl-min) {
    display: flex;
    width: 20px;
    padding-top: var(--spacing-xs);
    padding-bottom: var(--spacing-xs);
    font-size: var(--text-xs);
    letter-spacing: 1px;
  }
}

.product-poster-badge {
  display: flex;

  &.default-weekplanner-view {
    align-items: flex-end;
    padding: 0;
    border: 0;
    margin: 0;
    grid-area: img;

    @media screen and (max-width: @screen-lg-max) {
      grid-area: infoBadge;
    }
  }

  .product-detail-view {
    display: flex;

    .poster-badge {
      padding: var(--spacing-xs);
      margin-left: var(--spacing-xs);
      background-color: #b2b1b1;
      color: white;
      text-transform: uppercase;
    }
  }

  .product-list-view {
    padding: var(--spacing-xs);
    margin-top: var(--spacing-s);
    margin-left: var(--spacing-xs);
    background-color: #b2b1b1;
    color: white;
    text-transform: uppercase;
  }

  .weekplanner-view {
    width: auto;
    // stylelint-disable-next-line
    padding: 0 var(--spacing-xxs);
    background: #b2b1b1cc;
    color: var(--white);
    font-size: var(--text-xs);
    text-transform: uppercase;
  }
}

.weekplanner--small-screen {
  display: flex;

  .badge__small-screen {
    display: flex;
    height: 20px;
    align-items: center;
    justify-content: center;
    border: 0;
    margin-right: var(--spacing-xs);
    font-size: var(--text-xs);
    font-weight: 600;
    letter-spacing: normal;
  }
}

.weekplanner__simple-mode--badges {
  display: flex;
  flex-direction: column;
  align-items: center;

  .poster-badge {
    padding: 2px var(--spacing-xxs);
    background: #b2b1b1cc;
    color: var(--white);
    font-size: var(--text-xxxs);
    text-transform: uppercase;
  }
}

.hasStrikePriceVolumePrices {
  *:not(.volumePriceActive) .price {
    color: @primary-color;
  }
}

.productNewBadge,
.productFollowupBadge {
  p {
    display: inline-block;
    padding: @spacing-xs;
    margin: 0;
    background-color: fade(@secondary-color, 85%);
    color: @white;
    font-weight: @font-weight-bold;
  }

  &.overlayInfoBadge {
    z-index: @zindex-affix;
    overflow: hidden;
  }

  &.detailInfoBadge {
    grid-area: img;

    @media screen and (min-width: @screen-lg-min) {
      position: absolute;
      // with some img formats 0px here looks bad.
      top: 40px;
      left: -5px;
      width: auto;
    }
  }

  &.listInfoBadge {
    grid-area: img;
    // see productTile.less for width of grid-area: img
    @media screen and (min-width: @screen-md-min) {
      position: absolute;
      top: 0;
    }
  }

  &.weekplannerInfoBadge {
    grid-area: infoBadge;

    @media screen and (min-width: @screen-xl-min) {
      top: 0;
      grid-area: img;
    }
  }
}

.productFollowupBadge {
  cursor: pointer;
}
