//Necessary otherwise on mobile we get a white border to the right side
.ant-scrolling-effect {
  width: 100% !important;
  overflow-y: scroll !important;
}

.ant-modal-root {
  .ant-modal-close {
    top: 16px;
    padding-right: 12px;
  }

  .alternative-product-modal {
    .ant-modal-close {
      height: 66px;
    }

    .ant-modal-body {
      padding-bottom: 24px;
    }
  }

  .ant-modal-title {
    padding: var(--spacing-sm) 0;
    border-top: 2px solid @primary-color;
    border-bottom: 2px solid @primary-color;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }

  .ant-modal-mask,
  .ant-modal-wrap {
    z-index: 1050;
  }

  //Header and Content got different border
  .ant-modal-header {
    border-bottom: initial;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .ant-modal-content {
    border-radius: 5px;
  }

  .ant-modal-body {
    padding: 0 24px;

    .ant-input-affix-wrapper {
      padding: 7px 0;
      padding-right: 7px;
      border-radius: 5px;

      .ant-input-prefix {
        width: 50px;
        justify-content: center;
        opacity: 0.4;
      }
    }
  }

  .ant-modal-footer {
    padding: 10px 24px;
    border-top: initial;
  }
}
