.favouritelist__top-bar {
  @media only screen and (max-width: @screen-sm-max) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .button-print-favourite-list {
    height: var(--icon-size-default);
    padding: 8px 0 0;
    border: none;
    margin-left: var(--spacing-xs);
    background-color: transparent;

    svg {
      height: var(--icon-size-default);
      color: var(--table-borders);
    }

    &:hover,
    &:focus {
      svg {
        color: var(--primary-color);
      }
    }
    @media only screen and (min-width: @screen-md-min) {
      display: none;
    }
  }
}
