.standing-orders-table {
  .ant-table-column-sorter-inner .active svg {
    color: var(--white);
  }

  .ant-table-cell.ant-table-column-has-sorters:hover {
    color: var(--primary-color) !important;
  }

  .ant-table-cell.ant-table-column-has-sorters:focus {
    color: var(--white);
  }
}
