.quick-order-upload {
  .ant-form-item-label > label {
    color: var(--text-color) !important;
    font-weight: bold;
  }

  .ant-space-item a {
    color: var(--disabled-text);
  }
}
