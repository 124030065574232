.product-detail-modal {
  .ant-modal-title {
    display: none;
  }

  .ant-modal-content {
    max-width: 1500px;
    margin: 0 auto;
  }

  .ant-modal-body {
    padding-top: var(--spacing-2xl) !important;
    font-size: var(--text-default);
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
    padding-top: var(--spacing-xl) !important;
    padding-bottom: var(--spacing-xl) !important;

    button {
      width: 100%;
      letter-spacing: 1px;
      text-transform: uppercase;

      @media screen and (min-width: @screen-md-min) {
        width: 50%;
        max-width: 300px;
      }
    }
  }

  .loader-component {
    background-color: unset;
  }
}
