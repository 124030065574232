.business-unit-breakline {
  display: flex;
  height: 100%;
  flex-direction: row;
  border-width: 2px;
  border-style: none solid none none;
  margin-right: 9px;
  margin-left: 9px;

  @media screen and (max-width: @screen-md-max) {
    display: none;
  }
}

.business-unit-userNameGreetingContainer {
  @media screen and (min-width: @screen-lg-min) {
    display: flex;
    overflow: hidden;
    height: 28px;
    flex-direction: column;
    justify-content: space-between;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.business-unit-greeting-wrapper {
  display: flex;

  @media screen and (max-width: @screen-md-max) {
    display: none;
  }
}

.business-unit-greeting-paragraph {
  overflow: hidden;
  padding: 0;
  border: 0;
  margin: 0;
  font-size: @text-xs;
  letter-spacing: 0.24px;
  line-height: @text-xs;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (max-width: @screen-md-max) {
    overflow: visible;
    font-size: 26px;
    text-align: center;
    vertical-align: middle;
  }
}

.business-unit-username-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;

  @media screen and (max-width: @screen-md-max) {
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
  }
}

.business-unit-username-paragraph {
  overflow: hidden;
  padding: 0;
  border: 0;
  margin: 0;
  font-size: @text-xs;
  letter-spacing: 0.24px;
  line-height: @text-xs;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (max-width: @screen-md-max) {
    overflow: visible;
    font-size: 26px;
    text-align: center;
    vertical-align: middle;
  }
}

.business-unit-box-container {
  position: relative;
  display: flex;
  overflow: hidden;
  height: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: @spacing-xs @spacing-s;
  border: @white 2px solid;
  margin: 0;
  margin-left: @spacing-sm;
  background-color: @primary-color;
  border-radius: 5px;
  color: @white;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (max-width: @screen-md-max) {
    justify-content: center;
  }

  @media screen and (max-width: @screen-xs-max) {
    width: 50px;
    justify-content: center;
    padding: 5px;
    margin-left: 5px;
  }

  .business-unit-name-wrapper {
    display: flex;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    text-align: center;

    @media screen and (max-width: @screen-md-max) {
      display: none;
    }
  }

  .business-unit-paragraph {
    margin: 0;
    font-size: @text-xs;
    letter-spacing: 0.24px;
    line-height: @text-xs;
  }

  .business-unit-key-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-start;
    border: none;
    margin: 0;
    background-color: transparent;
    box-shadow: none;
    outline: none;
    text-align: center;

    @media screen and (max-width: @screen-md-max) {
      display: none;
    }
  }

  .business-unit-key {
    //overflow: hidden;
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: @text-default;
    font-weight: 600;
    letter-spacing: 0.32px;
    line-height: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media screen and (max-width: @screen-lg) {
      max-width: 75%;
      font-size: @text-xs;
    }
    @media screen and (max-width: @screen-md-min) {
      max-width: 100%;
      font-size: @text-xs;
    }
    @media screen and (max-width: @screen-xs) {
      display: none;
      font-size: 8px;
    }
  }

  .icon-business-unit {
    width: 24px;
    color: var(--white);
    fill: var(--white);

    @media screen and (min-width: @screen-lg-min) {
      display: none;
    }
  }

  &.ant-tooltip-open {
    background-color: @white;
    color: @primary-color;

    .icon-business-unit {
      color: @primary-color;
    }
  }

  &.can-switch {
    cursor: pointer;
  }

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    border-radius: 5px;
    border-top-right-radius: 0;
  }

  .ant-tooltip-placement-bottomRight {
    top: 38px !important;
    padding-top: 0;
  }

  .business-unit-grid {
    display: grid;
    height: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: @light-grey;
    border-radius: 10px;
    grid-template-areas: "radio customerId address";
    // stylelint-disable-next-line
    grid-template-columns: 50px 100px 150px;
  }

  .business-unit-grid-selected {
    background-color: @secondary-color-light;
  }

  .ant-radio-input {
    opacity: inherit;
  }

  input[type="radio"] {
    accent-color: @secondary-color;
    transform: scale(1.5);
  }

  .ant-radio-group {
    font-size: inherit;
  }

  .ant-radio-wrapper {
    align-items: center;
    justify-content: center;
    margin-right: 0;
    grid-area: radio;
  }

  .business-unit-tooltip-key {
    grid-area: customerId;
  }

  .business-unit-tooltip-address {
    grid-area: address;
  }
}
