@offerHeight: 300px;

.ad-webshop-dashboard {
  ul.slick-dots {
    margin-bottom: -@spacing-xl;

    li,
    li.slick-active {
      width: 12px;
      height: 12px;
      background-color: var(--primary-color);
      border-radius: 50%;

      button {
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }
    }

    li.slick-active button {
      background-color: var(--primary-color) !important;
    }
  }

  .picture-tile__thumbnail,
  .picture-tile__thumbnail picture,
  .picture-tile__thumbnail img {
    height: 100%;
  }

  .picture-tile__thumbnail img {
    object-fit: cover;
    object-position: top;
  }

  .picture-tile__overlay {
    height: 100%;
    max-height: 100%;
    background-color: unset !important;

    &__link .quick-link--primary--outlined:hover,
    &__link .quick-link--primary--outlined:focus {
      border-color: var(--primary-color);
    }
  }

  @media screen and (min-width: @screen-xs-max) {
    .ad-webshop-dashboard__picture-tile {
      height: @offerHeight!important;
      min-height: @offerHeight!important;
      max-height: @offerHeight!important;
    }
  }
}
