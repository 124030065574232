@videoImageWidth: 72px;
@videoImageHeight: calc(@videoImageWidth / 16 * 9);

.webshop-category-video {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 0 1.5px var(--disabled-bg);

  .ant-spin-nested-loading {
    min-width: @videoImageWidth;
    min-height: @videoImageHeight;
  }

  &__content {
    display: flex;
    overflow: hidden;
    height: @videoImageHeight;
  }

  &__thumbnail {
    position: relative;
    width: @videoImageWidth;
    min-width: @videoImageWidth;
    height: 100%;
    cursor: pointer;
    pointer-events: all;

    span.anticon {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      color: var(--white);

      svg {
        font-size: var(--text-xl);
      }
    }

    span.anticon,
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    & > div:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--primary-color);
      content: "";
      opacity: 0.5;
    }
  }

  &__details {
    overflow: hidden;
    width: 100%;
    padding: var(--spacing-xxs) var(--spacing-xs);
    background-color: var(--light-grey);
    font-size: var(--text-s);

    h5 {
      overflow: hidden;
      margin-bottom: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
