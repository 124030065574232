.business-unit-tile {
  display: grid;
  height: 75px;
  padding: var(--spacing-sm);
  margin: var(--spacing-xs);
  cursor: pointer;
  grid-template:
    "customer customerValue" 20px
    "address addressValue" auto / 120px auto;
  user-select: none;
}

.business-unit-tile:hover,
.business-unit-tile:focus,
.business-unit-tile--selected {
  border-color: transparent;
  background-color: var(--light-grey);
  box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
    0 5px 12px 4px rgb(0 0 0 / 9%);
}

.business-unit-tile:active {
  transform: translateY(4px);
}

.business-unit-list {
  display: flex;
  overflow: auto;
  min-height: 100px;
  max-height: 350px;
  flex-direction: column;
  margin-top: @spacing-s;
  row-gap: 10px;
}

.business-unit-missing {
  min-height: 100px;
  justify-content: center;
  line-height: 100px;
  text-align: center;
}

.business-unit-modal {
  .ant-btn:nth-child(1) {
    float: left;
  }

  .ant-input-clear-icon > span > svg {
    width: 2em;
    height: 2em;
  }

  .ant-col-md-6 {
    flex: auto;
  }

  .ant-btn-primary {
    width: 150px;
  }

  .ant-modal-title {
    h3 {
      margin: 0;
      font-weight: bold;

      @media only screen and (max-width: @screen-xs) {
        font-size: @text-default;
      }
    }
  }

  .ant-modal-close {
    height: 66px;

    @media only screen and (max-width: @screen-xs) {
      display: none;
    }
  }

  @media only screen and (max-width: @screen-sm-max) {
    .ant-message-login-success {
      position: relative;
      top: -50px !important;
    }
  }
}
